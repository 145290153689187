import React from "react";
import "./App.css";
import logo from './apple-touch-icon.png';
import eth from './Ethereum-icon-purple.svg.png';
import fox from './MetaMask_Fox.svg.png';
import swap from './j0zsB1681928748.png';
import asset1 from './photo_2023-04-19_19-38-12.jpg';
import woolball from './images.png';
import gcrTweet from './Download.png';
import asset2 from './n7HCU1681932905.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";

const App: React.FC = () => {

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const expandCard = (event: React.MouseEvent<HTMLDivElement>) => {
    const clickedCard = event.currentTarget;
    const isExpanded = clickedCard.classList.contains("expanded");
    clickedCard.classList.toggle("expanded", !isExpanded);
  }

  return (
    <div className="app">
      <header>
        <div className="image-container">
          <img src={logo} height={96}
            style={{
              paddingRight: "5%",
            }}
            className="header-logo"
          />
        </div>
        <nav>
          <ul>
            <li><button onClick={() => scrollToSection("intro")}>home</button></li>
            <li><button onClick={() => scrollToSection("about")}>about</button></li>
            <li><button onClick={() => scrollToSection("tokenomics")}>tokenomics</button></li>
            <li><button onClick={() => scrollToSection("how-to-buy")}>how to buy</button></li>
            <li><button onClick={() => scrollToSection("faq")}>faq</button></li>
          </ul>
        </nav>
        <div className="buttonContainer">
          <div className="menuButton"><a href="https://www.dextools.io/app/en/ether/pair-explorer/0x5f2bcd4616f8ef430d6a4dfcde959325dcdefac0" target="_blank">Chart</a></div>
          <div className="menuButton"><a href="https://app.uniswap.org/#/swap?outputCurrency=0x5F99C811b4c26f0aFb4072Ee6Cc99D3a03b75eE7" target="_blank">Buy LMEOW</a></div>
        </div>
      </header>
      <main>
        <section id="intro">
          <div className="intro">
            <img src={asset1} className="tacoCat" />
          </div>
          <div className="text"><h1><u>THE CUTEST MEME IN CRYPTO</u></h1>
            <p>Welcome to the wild and wacky world of LMEOW,
              where memes meet Ethereum and the laughs are endless.
              With a total supply of 4206900000, we're not just a meme token,
              we're a meme dynasty.
            </p>
            <div className="address-card">Contract Address: 0x5F99C811b4c26f0aFb4072Ee6Cc99D3a03b75eE7</div>
            <a href="https://t.me/lmeowofficial">Join Telegram<FontAwesomeIcon icon={faTelegram} style={{ marginLeft: "5px", marginTop: "15px", color: '#1DA1F2' }} /></a>
          </div>
        </section>
        <section id="about">
          <div className="custom-shape-divider-top-1681932086">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
            </svg></div>
          <div>
            <div className="headline"><h1 style={{ marginLeft: "30%" }}><u>ABOUT</u></h1></div>
            <div className="aboutContainer">
              <div className="frameContainer">
                <iframe src="https://app.uniswap.org/#/swap?outputCurrency=0x5F99C811b4c26f0aFb4072Ee6Cc99D3a03b75eE7" style={{
                  width: "100%",
                  height: "600px", borderRadius: "5px",
                }}
                ></iframe></div>
              <div className="about-text" style={{ alignItems: "center" }}>
                <p className="paragraph">Move over, doggos!
                  Cats are the new cool in the crypto world.
                  Forget the barking, it's time for purring profits!
                  Join our pawsome community and let's have a meowtastic time befriending the frogs and dethroning the dogs. 🐱🚀💰
                </p>
                <p className="paragraph">
                  LMEOW is ready to take over the world, get ready for the paradigm shift in the world of memes
                </p>
                <img src={gcrTweet} style={{ borderRadius: "5px", marginTop: "15%" }}
                  className="tweet" />
              </div>
            </div></div>
        </section>
        <section id="tokenomics">
          <div className="custom-shape-divider-top-1681932574">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
            </svg>
          </div>
          <div className="tokenomicsContainer">
            <div className="headline"><h1><u>TOKENOMICS</u></h1></div>
            <div className="cardContainer">
              <div className="card"><h3><u>NO TAXES</u></h3>
                <p>No Taxes, we believe its from the people for the people</p></div>
              <div className="card"><h3><u>4206900000 SUPPLY</u></h3>
                <p>Memeatic supply, for a memeatic currency</p></div>
              <div className="card"><h3><u>30% for CEX and Events</u></h3>
                <p>We will keep 30% of the supply for future CEX listings and events</p></div>
              <img src={asset2} height={128} className="spaceCat" /></div></div>
        </section>
        <section id="how-to-buy">
          <div className="custom-shape-divider-top-1681932086">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
            </svg></div>
          <div className="sectionContainer">
            <div className="headline">
              <h1><u>How to Buy</u></h1>
            </div>
            <div className="gridContainer">
              <div className="grid1">
                <img src={fox} height={48} width={48} />
                <h1><u>Create Crypto Wallet</u></h1>
                <p className="description">download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.</p>
              </div>
              <div className="grid2">
                <img src={eth} height={48} />
                <h1><u>Buy ETH</u></h1>
                <p className="description">have ETH in your wallet to switch to LMEOW.
                  If you don’t have any ETH, you can buy directly on metamask,
                  transfer from another wallet, or buy on another exchange and send it to your wallet.</p></div>
              <div className="grid3">
                <img src="https://uniswap.org/favicon.ico" /><h1><u>go to a DEX</u>
                </h1>
                <p className="description">connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the LMEOW token address into Uniswap, select LMEOW, and confirm. When Metamask prompts you for a wallet signature, sign.</p>
              </div>
              <div className="grid4">
                <img src={swap} height={48} />
                <h1><u>Swap to LMEOW</u></h1>
                <p className="description">switch ETH for LMEOW. We have ZERO taxes so you don’t need to worry about buying with a specific slippage,
                  although you may need to use slippage during times of market volatility.</p></div>
            </div></div>
        </section>
        <section id="faq">
          <div className="custom-shape-divider-top-1681932574">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
            </svg>
          </div>
          <div className="list-container">
            <div className="headline"><h1><u>FAQ</u></h1></div>
            <div className="list">
              <div className="expandable-card" onClick={expandCard}>
                <div className="expandable-card-content">WAS THERE PRESALE?</div>
                <div className="content">YES WE HAD A SMALL PRESALE, ACROSS SOME TRUSTABLE FRENS, TO BOOTSTRAP LIQUIDITY</div></div>
              <br></br>
              <div className="expandable-card" onClick={expandCard}>
                <div className="expandable-card-content">PRESALERS WONT DUMP?</div>
                <div className="content">PRESALERS AGREED TO NOT RECEIVE THE FULL BAG.
                  THEY WILL RECEIVE 20% OF THEIR ENTIRE BAG WEEKLY</div>
              </div>
              <br></br>
              <div className="expandable-card" onClick={expandCard}>
                <div className="expandable-card-contenet">WHATS THE CONTRACT ADDRESS</div>
                <div className="content" style={{ fontSize: "16px" }}>0x5f99c811b4c26f0afb4072ee6cc99d3a03b75ee7</div></div>
              <br></br>
              <div className="expandable-card" onClick={expandCard}>
                <div className="expandable-card-content">ARE U REALLY THE CUTEST MEME</div>
                <div className="content">WHAT DO YOU THINK LMEOW</div>
              </div>
              <br></br>
              <div className="expandable-card" onClick={expandCard}>
                <div className="expandable-card-content">WHY DID I NOT BUY YET? LMEOW</div>
                <div className="content">MY CUTENESS CAN BE DISTRACTING, THATS WHY LMEOW</div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div><a href="https://t.me/lmeowofficial" target="_blank" style={{ marginRight: "15px" }}><FontAwesomeIcon icon={faTelegram} style={{ color: '#1DA1F2' }} /></a>
          <a href="https://twitter.com/lmeowofficial" target="_blank"><FontAwesomeIcon icon={faTwitter} style={{ color: '#1DA1F2' }} /></a></div>
        Made with 💕 by meowti
      </footer>
    </div >
  );
};

export default App;